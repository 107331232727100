import { useDispatch } from 'react-redux';

import { DialogButton } from 'Components';
import { dialogName } from 'Components/dialog/searchBoxDialog.tsx';
import { dialogOpened } from 'Core/actions/dialog.ts';
import { usePopup } from 'Core/hooks/index.js';
import isMobile from 'Vendor/isMobile.js';
import { cloneSafe } from 'Utils/components.ts';
import SearchBox from './searchBox.tsx';

import type { FC } from 'react';
import type { TemplateFunction, TemplateFunctionInvoker, TemplateResult } from 'Components/types.ts';

export type Params = {
  searchBoxDialogButton: TemplateFunctionInvoker<unknown>;
  searchBox: TemplateFunctionInvoker<unknown>;
};

type Props = {
  template: TemplateFunction<Params>;
  name: string;
};

const SearchBoxDialogButton: FC<Props> = ({ template, ...props }) => {
  const dispatch = useDispatch();
  const { opened } = usePopup(dialogName);

  const searchBoxDialogButton: TemplateFunctionInvoker<unknown> = (templ) => {
    const dialogButtonProps = {
      template: templ,
      dialogName,
      key: 'button',
    };
    return (<DialogButton {...dialogButtonProps} />) as TemplateResult;
  };

  const searchBox: TemplateFunctionInvoker<unknown> = (templ) => {
    const searchBoxProps = {
      ...props,
      template: templ,
      key: 'search-box-dialog-button',
      disableAutoRequest: true,
      onInputFocus: () => {
        if (isMobile && !opened) {
          dispatch(dialogOpened(dialogName));
        }
      },
    };
    return (<SearchBox {...searchBoxProps} />) as TemplateResult;
  };

  return cloneSafe(template.call({ searchBoxDialogButton, searchBox }), null);
};

export default SearchBoxDialogButton;
