export default {
  onVehicleSelected,
  onVehicleDiscarded,
};

function setVehicleAtShopifyCart(vehicle) {
  fetch(`${window?.Shopify.routes.root}cart/update.js`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ attributes: vehicle }),
  }).then((response) => {
    if (!response.ok) {
      console.error(response.json());
    }
  });
}

function onVehicleDiscarded() {
  const emptyVehicle = window?.Convermax.templates.config.fitmentSearchConfig.fields.reduce(
    (acc, cur) => ({ ...acc, [cur]: '' }),
    {},
  );

  setVehicleAtShopifyCart(emptyVehicle);
}

function onVehicleSelected(vehicle) {
  setVehicleAtShopifyCart(vehicle);
}
