import { useDispatch } from 'react-redux';

import { toggle } from 'Core/actions/request.js';
import { cloneSafe } from 'Utils/components.ts';
import { getRangeFromValue, getRangeFiniteness } from 'Utils/ranged.ts';

export default function SimpleValue({ template, value, isSingleValue }) {
  const dispatch = useDispatch();

  const { isRanged, isUnique, field, value: facetValue, term, isSelected, treeLevel } = value;

  const range = isRanged ? getRangeFromValue(facetValue, term) : [];
  const rangeFiniteness = isRanged ? getRangeFiniteness(range) : [];

  const facetDivClass = [isSelected && 'selected', treeLevel && `cm_padding-${treeLevel}`]
    .filter(Boolean)
    .join(' ');

  const params = {
    ...value,
    facetDivClass,
    range,
    isCustomRange: isUnique,
    ...rangeFiniteness,
  };

  const component = template.call(params);
  return cloneSafe(component, null, {
    onClick: (e) => {
      if (!onClickPrevented(e, component)) {
        dispatch(toggle({ field, term }, { isSingleValue, mayDiscardValue: true }));
      }
    },
  });
}

function onClickPrevented({ nativeEvent }, { props: { onClick } }) {
  if (typeof onClick === 'function') {
    onClick(nativeEvent);
    return nativeEvent.defaultPrevented;
  }
  return false;
}
