import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeMaxCountByField, resetMaxCountByField } from 'Core/actions/request.js';
import { useCollapsible } from 'Core/hooks/index.js';
import { createMaxCountSelector } from 'Core/selectors/search.js';
import { FacetValue } from 'Models/index.ts';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import { distribute } from 'Utils/array.js';
import { cloneSafe } from 'Utils/components.ts';
import { simpleHandler } from 'Utils/roleHandler.js';
import { FacetsPanelConfig, SimpleValue, FilterInput } from '../common/index.ts';

const onInitHiddenValuesSelector = '.cmRepeater_Values';

export default function SimpleFacet({
  facet,
  facet: {
    alwaysShowCount,
    values: { length: valuesCount },
    facetedValues,
    field,
    isSingleValue,
    isTree,
  },
  config: { customValuesProcessor = (v) => v },
  templateFunc,
  facetRef,
  commonParams,
  commonParams: { isCollapsed: isFacetCollapsed },
  commonRoles,
}) {
  const dispatch = useDispatch();

  const [filterValue, setFilterValue] = useState('');

  const { showAllAlways, valuesCountToShowFilterInput } = useContext(FacetsPanelConfig);
  const maxCount = useSelector(createMaxCountSelector(field));

  const [onlyInitValuesDisplayed, showMoreToggle] = useCollapsible(
    facetRef,
    onInitHiddenValuesSelector,
    !showAllAlways,
    {
      disableCollapse: showAllAlways,
    },
  );

  if (!facetedValues.length) {
    return null;
  }

  const showFilterInput = facetedValues.length >= valuesCountToShowFilterInput;
  const filteredFacetValues = facetedValues.filter(
    (v) => v.isSelected || v.value.toLowerCase().includes(filterValue.toLowerCase()),
  );
  const filterInput = (template) => {
    if (!showFilterInput) {
      return null;
    }

    const props = {
      template,
      filterValue,
      facetName: facet.name,
      facetField: facet.field,
      onChange: (e) => setFilterValue(e.target.value),
      key: facet.name,
    };
    return <FilterInput {...props} />;
  };

  const orderedValues =
    showAllAlways || filteredFacetValues.length <= alwaysShowCount || !onlyInitValuesDisplayed || isTree
      ? filteredFacetValues
      : distribute(filteredFacetValues, (v) => v.isSelected).flat(); // TODO: don't use distribute

  // TODO: get rid of customValuesProcessor
  const valuesToShow = customValuesProcessor(orderedValues, {
    facet,
    showMoreCollapsed: onlyInitValuesDisplayed,
    FacetValue,
  }).map((value) => (templ) => {
    const props = {
      value,
      isSingleValue,
      template: templ,
      key: value.term,
    };
    return <SimpleValue {...props} />;
  });

  if (!valuesToShow) {
    return null;
  }

  const sliceIndex = showAllAlways ? orderedValues.length + 1 : alwaysShowCount;
  const [visibleOnInitValues, hiddenOnInitValues] = [
    valuesToShow.slice(0, sliceIndex),
    valuesToShow.slice(sliceIndex),
  ];

  const needShowMore = !showAllAlways && !!hiddenOnInitValues.length;
  const initMaxCount = requestConfig.maxCountByField[field] || null;
  const incrementalShowMore = needShowMore && initMaxCount && filteredFacetValues.length >= initMaxCount;
  const moreValuesToShow = incrementalShowMore
    ? !!maxCount && valuesCount === maxCount
    : onlyInitValuesDisplayed;

  const showMoreHandler =
    incrementalShowMore && !onlyInitValuesDisplayed
      ? () => {
          if (moreValuesToShow) {
            dispatch(removeMaxCountByField(field));
          } else {
            dispatch(resetMaxCountByField(field));
            showMoreToggle();
          }
        }
      : () => showMoreToggle();

  const onClick = simpleHandler({ ...commonRoles, toggleShowMore: showMoreHandler });

  const component = templateFunc.call({
    ...commonParams,
    filterInput,
    showFilterInput,
    inputNotEmpty: filterValue.length,
    needShowMore,
    moreValuesToShow,
    ShowAlwaysValues: visibleOnInitValues, // TODO: check 'isCollapsed'
    Values: !isFacetCollapsed && !onlyInitValuesDisplayed && hiddenOnInitValues,
    valuesCount,
    clearInput: () => setFilterValue(''),
  });
  return cloneSafe(component, facetRef, { onClick });
}
