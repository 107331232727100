import { createNextState as produce } from '@reduxjs/toolkit';
import _findKey from 'lodash-es/findKey.js';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  discard,
  filterQueryResetRequested,
  discardQuery,
  toggleColor,
  discardManyFields,
} from 'Core/actions/request.js';
import { createFilteredResponseSelectionSelector } from 'Core/selectors/search.js';
import { FacetValue } from 'Models/index.ts';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import Chip from './chip.tsx';

import type { FunctionComponent } from 'react';
import type { RepeaterFunctionInvoker, TemplateFunction, TemplateResult } from 'Components/types.ts';
import type { FacetValueFull } from 'Models/index.ts';
import type { JoinChipEntry, Params as ChipParams } from './chip.tsx';

export interface Params {
  chips: RepeaterFunctionInvoker<ChipParams>;
}

interface Props {
  template: TemplateFunction<Params>;
  fields?: string[];
  ignoreFields?: string[];
}

const FilterChips: FunctionComponent<Props> = ({ template, fields, ignoreFields }) => {
  const selection = useSelector(
    useMemo(() => createFilteredResponseSelectionSelector(fields, ignoreFields), [fields, ignoreFields]),
  ) as FacetValueFull[];

  if (!selection.length) {
    return null;
  }

  const chipMap = selection.reduce(
    (map, value) =>
      produce(map, (draft) => {
        const joinKey = _findKey(uiConfig.joinedChips, (fields) => fields.includes(value.field));
        if (!joinKey) {
          const fieldSelection = draft[value.field];
          if (fieldSelection && !('joinKey' in fieldSelection)) {
            fieldSelection.push(value);
          } else {
            draft[value.field] = [value];
          }
        } else {
          const joinSelection = draft[joinKey];
          if (joinSelection && 'joinKey' in joinSelection) {
            joinSelection.value[value.field] = value.value;
          } else {
            draft[joinKey] = { joinKey, value: { [value.field]: value.value } };
          }
        }
      }),
    {} as Record<string, FacetValueFull[] | JoinChipEntry>,
  );

  const chips = Object.values(chipMap)
    .flat()
    .map((value) => (templ: TemplateFunction<ChipParams>) => {
      const discardValue =
        'joinKey' in value
          ? (_value, options) => discardManyFields(uiConfig.joinedChips[value.joinKey], options)
          : value.field === 'query'
            ? discardQuery
            : value.field === 'filterQuery'
              ? filterQueryResetRequested
              : FacetValue.isColor(value)
                ? toggleColor
                : discard;
      const extendedProps = {
        template: templ,
        value,
        discardValue,
        key: 'joinKey' in value ? value.joinKey : FacetValue.termKey(value),
      };
      return (<Chip {...extendedProps} />) as TemplateResult;
    });

  return template.call({ chips });
};

export default FilterChips;
