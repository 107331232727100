import RangedListFacet from './rangedListFacet.tsx';
import RangedSliderFacet from './rangedSliderFacet.tsx';

import type { FC } from 'react';
import type { FacetCommonProps } from '../baseFacet.js';

const RangedFacet: FC<FacetCommonProps> = ({ facet, ...props }) => {
  if (!facet.isRanged) {
    return null;
  }

  if (facet.ranges?.length) {
    return <RangedListFacet facet={facet} {...props} />;
  }

  return <RangedSliderFacet facet={facet} {...props} />;
};

export default RangedFacet;
